const hardskills = [
  {
    "name": "Svelte",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/svelte.svg",
    // "image": require("../images/icons/svelte.svg"),
    "image": require("../images/icons/svelte.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Python",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/python.svg",
    "image": require("../images/icons/python.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Bootstrap",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/bootstrap.svg",
    "image": require("../images/icons/bootstrap.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Css3",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/css3.svg",
    "image": require("../images/icons/css3.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Docker",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/docker.svg",
    "image": require("../images/icons/docker.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Git",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/git.svg",
    "image": require("../images/icons/git.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Html5",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/html5.svg",
    "image": require("../images/icons/html5.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Javascript",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/javascript.svg",
    "image": require("../images/icons/javascript.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "MySQL",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/mysql.svg",
    "image": require("../images/icons/mysql.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Node.js",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/nodejs.svg",
    "image": require("../images/icons/nodejs.svg").default,
    "description": "Tecnologia aprendida"
  },
  // {
  //   "name": "Php",
  // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/php.svg"
  // "image": require("../images/icons/php.svg"),.default
  //   "description": "Tecnologia aprendida"
  // },
  {
    "name": "React.js",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/react.svg",
    "image": require("../images/icons/react.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Redux",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/redux.svg",
    "image": require("../images/icons/redux.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Sass",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/sass.svg",
    "image": require("../images/icons/sass.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Tailwind Css",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/tailwindcss.svg",
    "image": require("../images/icons/tailwindcss.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Typescript",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/typescript.svg",
    "image": require("../images/icons/typescript.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "React Router",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/router.svg",
    "image": require("../images/icons/react-router.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Scrum",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/scrum.svg",
    "image": require("../images/icons/scrum.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Kanban",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/kanban.svg",
    "image": require("../images/icons/kanban.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Express.js",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/express.svg",
    "image": require("../images/icons/express.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Jest",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/jest.svg",
    "image": require("../images/icons/jest.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Styled Components",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/components.svg",
    "image": require("../images/icons/styled-components.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Mocha.js",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/mochajs.svg",
    "image": require("../images/icons/mochajs.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Chai.js",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/chaijs.svg",
    "image": require("../images/icons/chaijs.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Sinon.js",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/sinonjs.svg",
    "image": require("../images/icons/sinonjs.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "Sequelize.js",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/sequelizejs.svg",
    "image": require("../images/icons/sequelizejs.svg").default,
    "description": "Tecnologia aprendida"
  },
  {
    "name": "JSON Web Tokens",
    // "image": "http://api.gabrielbenedicto.com:4000/view/icons_tech/jwt.svg",
    "image": require("../images/icons/jwt.svg").default,
    "description": "Tecnologia aprendida"
  },
];

export default hardskills;
